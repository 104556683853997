
import { Box, Card } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import HttpServices from 'src/services/httpService';
import { reportModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import Spinner from 'src/utils/Loader/spinner';
import ScheduledReportGrid from './Grid/PostGrid.component';


const ScheduledReportsView = () => {

  const [scheduledList, setScheduledList] = useState([]);
  const { showToast } = useToaster();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getScheduledList();
  }, [])

  const successCallback = (data, message) => {
    setLoader(false);
    setScheduledList(data?.data);
  }
  const failureCallback = (message) => {
    setLoader(false);
    message && showToast(message, 'e');
  }

  const getScheduledList = () => {
   
    let getProps = {
      url: reportModuleURL.getAllScheduledReport,
      successCallback,
      failureCallback
    }
    HttpServices.Get(getProps);
  }
  const handleReCallScheduleReport = () => {
    setLoader(true);
    getScheduledList()
  }

  return (
    <>
      <Helmet>
        <title>Scheduled Reports</title>
      </Helmet>
      <Box>
        {loader ?
          <Spinner />
          :
          <Card>
            <ScheduledReportGrid scheduledList={scheduledList}  handleReCallScheduleReport={handleReCallScheduleReport} />
          </Card>
        }
      </Box>
    </>
  );
};

export default ScheduledReportsView;
