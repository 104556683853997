import {
    Typography,
    Stack,
    TableContainer,
} from '@mui/material';
import NestedCard from 'src/components/reports/report-cards/nested-card';
import EngagementNestedCard from 'src/components/reports/report-cards/engagement-nested-card';

const ReportComponent = ({ connectedProfileId, startDate, endDate, viewBy, summary, serviceName }) => {
    const summaryFilter = summary?.report_card?.filter((report) => report.is_display);

    const renderComponent = (data) => {
        switch (data.card_type) {
            case "nested_card":
                return (
                    <Stack spacing={2} direction='column'>
                        <Typography variant="h5" gutterBottom>
                            {data.card_name}
                        </Typography>
                        <NestedCard connectedProfileId={connectedProfileId}
                            startDate={startDate}
                            endDate={endDate}
                            viewBy={viewBy}
                            audienceSummary={data} serviceName={serviceName}
                            reportId={summary.id} />
                    </Stack>
                );
            case "nested_card_and_table":
                return (
                    <Stack spacing={2} direction='column' sx={{ mt: 1 }}>
                        <Typography variant="h5" gutterBottom>
                            {data.card_name}
                        </Typography>
                        <EngagementNestedCard connectedProfileId={connectedProfileId}
                            startDate={startDate}
                            endDate={endDate}
                            viewBy={viewBy}
                            engagementSummary={data}
                            serviceName={serviceName}
                            reportId={summary.id} />
                    </Stack>
                );
            default:
                return null;
        }
    };

    return (
        <TableContainer sx={{ mt: 1, minHeight: 'calc(100vh - 290px)', maxHeight: 'calc(100vh - 290px)' }}>
            {summaryFilter && summaryFilter?.map((data) => renderComponent(data))}
        </TableContainer>
    );
};

export default ReportComponent;
