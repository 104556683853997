import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormProvider } from 'src/components/hook-form';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import ValidationTool from 'src/utils/validationHelper';
import InitialSetup from '../common/initial-setup.component';

const CreateOrganizationAndBrand = () => {
  const [formSchema, setFormSchema] = useState({});
  const [formValidationErrors, setFormValidationErrors] = useState({});
  const [orgName, setOrgName] = useState('');
  const [brandName, setBrandName] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [timeZone,setTimeZone] = useState("UTC");
  const { showToast } = useToaster();
  const navigate = useNavigate();

  useEffect(() => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if(localTimeZone) setTimeZone(localTimeZone);
    generateFormModelSchema();
  }, []);

  const handleInputChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case 'orgName':
        setOrgName(value);
        break;
      case 'brandName':
        setBrandName(value);
        break;
      default:
        break;
    }
  };
  const generateFormModelSchema = () => {
    let fields = [
      ValidationTool.GenerateSchema('orgName', 'Org Name', 'TEXT', true),
      ValidationTool.GenerateSchema('brandName', 'Brand Name', 'TEXT', true),
    ];
    const schema = ValidationTool.GenerateSchemaModel(fields);
    setFormSchema(schema);
  };
  const planSuccessCallback = (data, message) => {
    let isSetUpDone = InitialSetup(data);
    if (isSetUpDone) {

    }
    else {

    }
  };
  const handleNavigate = (route) => {
    navigate(route, { replace: true });
  };
  const initCallFailureCallback = () => {
    CommonHelper.Logout();
  };
  const successCallback = (data, message) => {
    // CommonHelper.AppInitCall(planSuccessCallback, initCallFailureCallback);
    window.location.reload();
  };
  const failureCallback = (message) => {
    message && showToast(message);
    setLoading(false);
  };
  const onSubmit = () => {
    let orgProps = {
      org_name: orgName,
      brand_name: brandName,
      timezone: timeZone
    };
    setLoading(true);
    let postProps = {
      url: userModuleURL.createOrg,
      body: orgProps,
      successCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const handleSubmitForm = () => {
    let userForm = {
      orgName: orgName,
      brandName: brandName,
    };
    let { formValidationErrors, isValidForm } = ValidationTool.FormValidation(formSchema, userForm);
    if (isValidForm) {
      setFormValidationErrors({});
      onSubmit();
    } else setFormValidationErrors(formValidationErrors);
  };
  const defaultValues = {
    orgName: orgName,
    brandName: brandName,
  };
  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit } = methods;
  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
        <Stack spacing={3}>
          <TextField
            name="orgName"
            label="Organization Name"
            value={orgName}
            onChange={handleInputChange}
            error={formValidationErrors?.orgName}
            helperText={formValidationErrors?.orgName}
          />
          <TextField
            name="brandName"
            label="Brand Name"
            value={brandName}
            onChange={handleInputChange}
            error={formValidationErrors?.brandName}
            helperText={formValidationErrors?.brandName}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 3 }}>
          <LoadingButton size="large" loading={isLoading} variant="contained" type="submit" fullWidth>
            Create Organization
          </LoadingButton>
        </Stack>
      </FormProvider>
    </>
  );
};
export default CreateOrganizationAndBrand;
