import { TablePagination } from "@mui/material";

const ScheduledReportGridPagination = ({ scheduledList,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage
}) => {

    return (
        <>
            <TablePagination
                component="div"
                rowsPerPageOptions={[10, 20, 30, 50]}
                count={scheduledList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}

export default ScheduledReportGridPagination;