import { TimeHelper } from "src/utils/time-helper";
import { RHFSelect } from "../hook-form";
import { useState } from "react";
import { useTheme, styled } from '@mui/material/styles';


const TimeZoneSelect = ({ ...other }) => {
  const theme = useTheme();
  const timeZoneList = TimeHelper.GetTimeZoneList();
  const [selectedValue, setSelectedValue] = useState('UTC'); // Default value.

  const handleChange = (event) => {
    setSelectedValue(event.target.value); // Update selected value.
  };

  return (
    <RHFSelect
      name="timeZone"
      placeholder="TimeZone"
      label="TimeZone"
      defaultValue="UTC"
      onChange={handleChange}
      {...other}
      sx={{
        color: selectedValue === 'UTC' ? theme.palette.text.primary : 'black',
      }}
    >
      <option value="UTC">
      UTC
      </option>
      {timeZoneList.map((option) => (
        <option key={option.timeZone} value={option.timeZone}>
          {option.timeZone}: UTC{option.offset}
        </option>
      ))}
    </RHFSelect>
  );
};

export default TimeZoneSelect;