import React, { useState, useEffect } from 'react';
import {
    Card,
    Grid,
    Stack,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useToaster } from 'src/utils/toaster/toasterContext';
import ReportHttpServices from 'src/services/reportsHttpService';
import { internalReportModuleURL } from 'src/services/urlService'
import BestPostCardDetails from 'src/components/reports/summary-cards/bestPostCard';
import TableDonutChart from 'src/components/reports/summary-cards/summaryTableDonutCard';
import SummaryCommonCard from 'src/components/reports/summary-cards/summaryCommonCard';
import NoReportCard from 'src/components/reports/summary-cards/noReportCard';
import { useLocation } from 'react-router-dom';


const EngagementNestedCard = ({ connectedProfileId, startDate, endDate, viewBy, engagementSummary, serviceName }) => {
    const theme = useTheme();
    const [isLoading, setLoading] = useState(true);
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'));
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const urlBrandId = urlParams.get('brand_id');
    const urlToken = urlParams.get('token');
    const [postEngagementSummary, setPostEngagementSummary] = useState({
        totalPosts: null,
        totalEngagement: null,
        bestPost: null,
        engagementPostType: null
    });
    const { showToast } = useToaster();

    useEffect(() => {
        if (connectedProfileId) {
            getEngagementSummary();
        }
    }, [connectedProfileId, startDate, endDate]);


    const getEngagementSummary = () => {
        let body = {
            connected_profile_id: connectedProfileId,
            report_id: engagementSummary.id,
            date_range: {
                from: startDate,
                to: endDate,
            },
            view_by: "day"
        };
        let getProps = {
            url: `${internalReportModuleURL.scheduledReports}${serviceName}`,
            body: body,
            brandId: urlBrandId,
            token: urlToken,
            successCallback: postEngagementSuccessCallback,
            failureCallback: postEngagementFailureCallback
        };
        ReportHttpServices.Post(getProps);
    };

    const postEngagementFailureCallback = (message) => {
        setPostEngagementSummary({
            totalPosts: null,
            totalEngagement: null,
            bestPost: null,
            engagementPostType: null
        });
        if (message) showToast(message, "e");
        setLoading(false);
    };

    const postEngagementSuccessCallback = (data) => {
        const responseData = data[0]?.data || {};
        setPostEngagementSummary({
            totalPosts: responseData?.total_posts,
            totalEngagement: responseData?.total_engagement,
            bestPost: responseData?.best_post,
            engagementPostType: responseData?.engagement_post_type
        });
        setLoading(false);
    };

    return (
        <Card>
            {isLoading ? (
                <Grid container spacing={2}>
                    {Array.from({ length: 4 }).map((_, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index} style={{ border: '1px solid #ddd' }}>
                            <NoReportCard />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6} xl={2.5}>
                        <Stack direction={isMobile ? 'row' : 'column'}>
                            {postEngagementSummary.totalPosts?.card_type !== 'no-report' ? (
                                <SummaryCommonCard
                                    title={postEngagementSummary.totalPosts?.title}
                                    data={postEngagementSummary.totalPosts?.data}
                                    percentage={postEngagementSummary.totalPosts?.percentage}
                                    percentageState={postEngagementSummary.totalPosts?.percentage_state}
                                    averageData={postEngagementSummary.totalPosts?.average_data}
                                />
                            ) : (
                                <NoReportCard />
                            )}
                            <div style={{ border: '1px solid #ddd' }} />
                            {postEngagementSummary.totalEngagement?.card_type !== 'no-report' ? (
                                <SummaryCommonCard
                                    title={postEngagementSummary.totalEngagement?.title}
                                    data={postEngagementSummary.totalEngagement?.data}
                                    percentage={postEngagementSummary.totalEngagement?.percentage}
                                    percentageState={postEngagementSummary.totalEngagement?.percentage_state}
                                    averageData={postEngagementSummary.totalEngagement?.average_data}
                                />
                            ) : (
                                <NoReportCard />
                            )}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6} xl={2.5} justifyContent="center" alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #ddd' }}>
                        {postEngagementSummary.bestPost?.card_type !== 'no-report' ? (
                            <BestPostCardDetails
                                title={postEngagementSummary.bestPost?.title}
                                comments={postEngagementSummary.bestPost?.comment_count}
                                createdTime={postEngagementSummary.bestPost?.created_time}
                                description={postEngagementSummary.bestPost?.description}
                                engagement={postEngagementSummary.bestPost?.engagement}
                                engagementRate={postEngagementSummary.bestPost?.engagement_rate}
                                mediaUrl={postEngagementSummary.bestPost?.media_url}
                                link={postEngagementSummary.bestPost?.link}
                                reactions={postEngagementSummary.bestPost?.reaction}
                                shares={postEngagementSummary.bestPost?.share_count}
                            />
                        ) : (
                            <NoReportCard />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={isMobile ? 12 : 6} xl={7} style={{ border: '1px solid #ddd' }}>
                        {postEngagementSummary.engagementPostType?.card_type !== 'no-report' ? (
                            <TableDonutChart
                                title={postEngagementSummary.engagementPostType?.title}
                                engagementPostType={postEngagementSummary.engagementPostType?.engagement_by_type}
                                value={postEngagementSummary.engagementPostType?.value}
                                labels={postEngagementSummary.engagementPostType?.key}
                            />
                        ) : (
                            <NoReportCard />
                        )}
                    </Grid>
                </Grid>
            )}
        </Card>
    );
};

export default EngagementNestedCard;
