import React, { useEffect, useState } from 'react';
import { Card, Grid } from '@mui/material';
import SummaryCommonCard from 'src/components/reports/summary-cards/summaryCommonCard';
import DonutChart from 'src/components/reports/summary-cards/summaryDonutCard';
import ReportHttpServices from 'src/services/reportsHttpService';
import { internalReportModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { useLocation } from 'react-router-dom';
import NoReportCard from 'src/components/reports/summary-cards/noReportCard';

const AudienceSummaryComponent = ({ connectedProfileId, startDate, endDate, viewBy,audienceSummary,serviceName}) => { 
    const [isLoading, setLoading] = useState(true);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const urlBrandId = urlParams.get('brand_id');
    const urlToken = urlParams.get('token');

    const [summaryData, setSummaryData] = useState([]);
    const { showToast } = useToaster();

    useEffect(() => {
        if (connectedProfileId) {
            getAudienceSummary();
        }
    }, [connectedProfileId, startDate, endDate]);

    const getAudienceSummary = () => {
        setLoading(true);
        let body = {
            connected_profile_id: connectedProfileId,
            report_id: audienceSummary.id,
            date_range: {
                from: startDate,
                to: endDate,
            },
            view_by: "day"
        };
        let getProps = {
            url: `${internalReportModuleURL.scheduledReports}${serviceName}`,
            body: body,
            brandId: urlBrandId,
            token: urlToken,
            successCallback,
            failureCallback
        };
        ReportHttpServices.Post(getProps);
    };

    const failureCallback = (message) => {
        setLoading(false);
        showToast(message, "e");
        setSummaryData([]);
    };

    const successCallback = (data) => {
        const responseData = Array.isArray(data[0]?.data) ? data[0]?.data : [];
        setSummaryData(responseData);
        setLoading(false);
    };

    const renderComponent = (data) => {
        switch (data.card_type) {
            case "square":
                return (
                    <Grid item xs={12} sm={6} md={2.5} key={data.id} style={{ border: '1px solid #ddd' }}>
                        <SummaryCommonCard
                            title={data?.title}
                            data={data?.data}
                            percentage={data?.percentage}
                            percentageState={data?.percentage_state}
                            averageData={data?.average_data}
                        />
                    </Grid>
                );
            case "donut":
                return (
                    <Grid item xs={12} sm={6} md={4.5} key={data.id} style={{ border: '1px solid #ddd' }}>
                        <DonutChart
                            title={data?.title}
                            value={data?.value}
                            labels={data?.key}
                        />
                    </Grid>
                );
            case "no-report":
                return (
                    <Grid item xs={12} sm={6} md={2.5} key={data.id} style={{ border: '1px solid #ddd' }}>
                        <NoReportCard />
                    </Grid>
                );
            default:
                return null;
        }
    };

    return (
        <Card>
            {isLoading ? (
                <Grid container spacing={2}>
                    {Array.from({ length: 4 }).map((_, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index} style={{ border: '1px solid #ddd' }}>
                            <NoReportCard />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    {summaryData?.map((data) => renderComponent(data))}
                </Grid>
            )}
        </Card>
    );
};

export default AudienceSummaryComponent;
