import { useTheme } from '@emotion/react';
import { Hidden, ListItemText, TableBody, useMediaQuery, Chip } from '@mui/material';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import { CommonHelper } from 'src/utils/commonHelper';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import ScheduledReportGridAction from './PostGridAction.component';
import PostPreview from 'src/pages/scheduledPost/Preview/post-preview.component';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';

const ScheduledReportGridBody = ({ scheduledList, page, rowsPerPage, handleReCallScheduleReport }) => {
  const theme = useTheme();
  let maxWidth = useMediaQuery(theme.breakpoints.down('sm')) ? 500 : 400;


  return (
    <>
      <TableBody>
        {scheduledList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
          return (
            <>
              <StyledComponent.StyledTableRow
              >
                <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell>
                    <ListItemText
                      sx={{
                        m: 0,
                        '.MuiListItemText-primary': {
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontSize: '0.875rem',
                        },
                        '.MuiListItemText-secondary': {
                          color: '#637381',
                          // margin: '4px 0px 0px',
                          fontSize: '0.75rem',
                          textTransform: 'lowercase',
                        },
                      }}
                      primary={CommonHelper.formatDateAndTime(row.created_time, 'date')}
                      secondary={CommonHelper.formatDateAndTime(row.created_time, 'time')}
                    ></ListItemText>
                  </StyledComponent.StyledTableCell>
                </Hidden>
                <StyledComponent.StyledTableCell>
                  {row.subject}
                </StyledComponent.StyledTableCell>
                <StyledComponent.StyledTableCell>
                  {PostDetailsHelper.GetPostContent(row.date_range, maxWidth)}
                </StyledComponent.StyledTableCell>
                <StyledComponent.StyledTableCell>
                  {row.schedule_type}
                </StyledComponent.StyledTableCell>
                <StyledComponent.StyledTableCell>
                  {row.group_by}
                </StyledComponent.StyledTableCell>
                <StyledComponent.StyledTableCell>
                  {row.report_format}
                </StyledComponent.StyledTableCell>
                <StyledComponent.StyledTableCell>
                  {row.email_addresses?.map((email, index) => (
                    <Chip
                      key={index}
                      label={email}
                      sx={{
                        margin: '2px',
                        fontSize: '0.75rem',
                        backgroundColor: '#e0f7fa',
                      }}
                    />
                  ))}
                </StyledComponent.StyledTableCell>
                {/* <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell onClick={(event) => event.stopPropagation()}>
                    {PostDetailsHelper.getPostMedias(row.media_url)}
                  </StyledComponent.StyledTableCell>
                </Hidden>
                <StyledComponent.StyledTableCell>
                  {PostDetailsHelper.getProfile(row.created_by.name, row.created_by.email, row.created_by.profile_pic)}
                </StyledComponent.StyledTableCell>
                <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell>
                    {PostDetailsHelper.getSocialMediaViewWithTooltip(row.connected_profile_details)}
                  </StyledComponent.StyledTableCell>
                </Hidden> */}
                <StyledComponent.StyledTableCell>
                  <StyledComponent.HoverContent
                    className="hover-content"
                    onClick={(event) => event.stopPropagation()}
                    sx={{ width: '1rem' }}
                  >
                    <ScheduledReportGridAction scheduledList={row} handleReCallScheduleReport={handleReCallScheduleReport} />
                  </StyledComponent.HoverContent>
                </StyledComponent.StyledTableCell>
              </StyledComponent.StyledTableRow>
            </>
          );
        })}
      </TableBody>
    </>
  );
};

export default ScheduledReportGridBody;
