import { CameraAlt as ImageUploadIcon, Collections as MediaGallery, Info as InfoIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { useState } from 'react';
import ImageDraggable from 'src/components/image/image-draggable';
import LinearProgressBar from 'src/components/progress-bar/progressBar.component';
import { CommonHelper } from 'src/utils/commonHelper';
import MediaLibrary from '../media-library/media-library.component';
import MediaUploadComponent from '../media-library/media-upload.component';
import UploadMediaPreview from './post-uploadmedia.component';
import TextIconLabel from 'src/components/TextIconLabel';

const PostUploadComponent = ({ handleClose, handleUpload, fileUploadprogress }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentTab, setCurrentTab] = useState('Image Upload');
  const [mediaFiles, setMediaFiles] = useState([]);
  const [privateMediaFiles, setPrivateMediaFiles] = useState([]);
  const [isUploadLoading, setUploadLoading] = useState(false);

  const handleMediaFiles = (files) => {
    let mFiles = [...mediaFiles];
    mFiles = mFiles.concat(files);
    setMediaFiles([...mFiles]);
  };
  const handleFilesfromMediaLibrary = (file, isSelected) => {
    let mFiles = [...privateMediaFiles];
    if (isSelected) {
      mFiles.push(file);
    } else {
      let index = mFiles.findIndex((a) => a.name === file.name);
      if (index >= 0) {
        mFiles.splice(index, 1);
      }
    }
    setPrivateMediaFiles([...mFiles]);
  };
  const ACCOUNT_TABS = [
    {
      value: 'Image Upload',
      icon: <ImageUploadIcon sx={{ width: '20px', height: '20px' }} />,
      component: <MediaUploadComponent handleMediaFiles={handleMediaFiles} />,
    },
    {
      value: 'Image Library',
      icon: <MediaGallery sx={{ width: '20px', height: '20px' }} />,
      component: <MediaLibrary isFromNewPost={true} handleMediaFiles={handleFilesfromMediaLibrary} />,
    },
  ];

  const handleReOrderFiles = (oldIndex, newIndex) => {
    setMediaFiles((prevImages) => {
      const newImages = [...prevImages];
      return CommonHelper.ReArrangeArray(newImages, oldIndex, newIndex);
    });
  };
  const handleReOrderPrivateFiles = (oldIndex, newIndex) => {
    setPrivateMediaFiles((prevImages) => {
      const newImages = [...prevImages];
      return CommonHelper.ReArrangeArray(newImages, oldIndex, newIndex);
    });
  };
  const handleRemoveFiles = (file) => {
    let files = [...mediaFiles];
    let index = files.findIndex((a) => a.name === file.name);
    if (index >= 0) files.splice(index, 1);
    setMediaFiles([...files]);
  };
  const handlePrivateRemoveFiles = (file) => {
    let files = [...privateMediaFiles];
    let index = files.findIndex((a) => a.name === file.name);
    if (index >= 0) files.splice(index, 1);
    setPrivateMediaFiles([...files]);
  };
  const hanldeUploadMediaFiles = () => {
    setUploadLoading(true);
    handleUpload(mediaFiles, privateMediaFiles);
  };
  const onClose = () => {
    handleClose();
  };
  return (
    <Dialog open onClose={handleClose} minWidth="md">
      <DialogTitle>Post Upload</DialogTitle>
      <DialogContent>
        <Box>
          <Tabs
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={(e, value) => setCurrentTab(value)}
            sx={{ gap: '0.5rem', minHeight: '37px' }}
          >
            {ACCOUNT_TABS.map((tab) => (
              <Tab
                disableRipple
                iconPosition="start"
                key={tab.value}
                label={!isMobile && capitalCase(tab.value)}
                icon={tab.icon}
                value={tab.value}
                sx={{
                  flexDirection: 'row',
                  gap: '0.25rem',
                  padding: 0,
                  minHeight: '10px',
                  mr: '0.75rem',
                  fontWeight: 600,
                }}
              />
            ))}
          </Tabs>

          <Box sx={{ mb: 3 }} />

          {ACCOUNT_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box>{tab.component}</Box>;
          })}
        </Box>
      </DialogContent>
      {(mediaFiles.length > 0 || privateMediaFiles.length > 0) && (
        <DialogActions
          sx={{
            alignItems: 'flex-start',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '6rem',
            overflowY: 'auto',
          }}
        >
          <UploadMediaPreview
            mediaFiles={mediaFiles}
            privateMediaFiles={privateMediaFiles}
            handleRemoveFiles={handleRemoveFiles}
            handlePrivateRemoveFiles={handlePrivateRemoveFiles}
            isFile={true}
          />
        </DialogActions>
      )}
      <DialogActions
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <div style={{ flex: 1 }}>{fileUploadprogress > 0 && <LinearProgressBar value={fileUploadprogress} />}</div>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button variant="outlined" color="error" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton variant="contained" onClick={hanldeUploadMediaFiles} autoFocus loading={isUploadLoading} disabled={!(mediaFiles.length > 0 || privateMediaFiles.length > 0)}>
            Upload
          </LoadingButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};
export default PostUploadComponent;
