import { PostAdd } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { MenuItem, Popover,CircularProgress,Box} from '@mui/material';

const ScheduledReportActionPopup = ({
    popoverElement,
    handleCloseMenu,
    handleEditScheduledReport,
    handleDeleteReport,
    loading
}) => {
    return (
        <Popover
            open={Boolean(popoverElement)}
            anchorEl={popoverElement}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: { p: 1, width: 'auto', '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 } },
            }}
        >
            {' '}
            {loading ? (
                   <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                   <CircularProgress size={24} />
               </Box>
                ) : 
                <>
            <MenuItem sx={{ color: 'info.main' }} onClick={handleEditScheduledReport}>
                <PostAdd sx={{ mr: 1 }} /> Edit Scheduled Report
            </MenuItem>
            <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteReport}>
                <DeleteForeverIcon sx={{ mr: 1 }} /> Delete Scheduled Report
            </MenuItem>
            </>
            }
        </Popover>
    );
};
export default ScheduledReportActionPopup;
