import { Box, Button, Card, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import NextBillPaymentComponent from 'src/components/stripe/nextBill-payment.component';
import HttpServices from "src/services/httpService";
import { paymentModuleUrl } from "src/services/urlService";
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from "src/utils/toaster/toasterContext";

const NextPlanBillDetails = ({ hasSubscription, hasTrailPeriod,expireDate, currency, isAppsumoUser, defaultPlanName }) => {
    const [isPaymentOn, setPaymentOn] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [expiresOn, setPlanExpiresOn] = useState("");
    const [lifeTimePlanName, setLifeTimePlanName] = useState("");
    const [newExpiresOn, setNewPlanExpiresOn] = useState("");
    // const [hasActiveSubscription, setActiveSubscription] = useState(false);
    const { showToast } = useToaster();

    useEffect(() => {
        if (hasSubscription) {
            getNextPriceBill();
        } if (isAppsumoUser) {
            switch (defaultPlanName) {
                case 'brand_lifetime':
                    setLifeTimePlanName("Brand");
                    break;
                case 'agency_lifetime':
                    setLifeTimePlanName("Agency");
                    break;
                case 'mini_agency_lifetime':
                    setLifeTimePlanName("Agency");
                    break;
                case 'business_lifetime':
                    setLifeTimePlanName("Business");
                    break;
                case 'team_lifetime':
                        setLifeTimePlanName("Team");
                        break;
                default:
                    break;
            }
        }
    }, [currency])

    const handlePayment = (show) => {
        setPaymentOn(show);
    }
    const failureCallback = (message) => {
        message && showToast(message);
    }

    const successCallback = (data, message) => {
        setTotalAmount(data.amount);
        setPlanExpiresOn(data.expire_date);
        setNewPlanExpiresOn(data.new_expire_date);
        // setActiveSubscription(data.has_active_subscription);
    }

    const getNextPriceBill = () => {
        let postProps = {
            url: paymentModuleUrl.getForNextBill + currency,
            successCallback: successCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: hasSubscription ? 'flex-end' : 'flex-start' }}>
            {hasSubscription ? (
                <Card sx={{
                    p: 1,
                    mt: 1,
                    width: '50%',
                    borderRadius: 1,
                }}>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                        Current Plan pay to next month
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography sx={{ fontWeight: 700, color: 'text.secondary' }}>Expires On:</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                            {CommonHelper.formatDateAndTime(expiresOn, 'date')}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography sx={{ fontWeight: 700, color: 'text.secondary' }}>New Expires On:</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                            {CommonHelper.formatDateAndTime(newExpiresOn, 'date')}
                        </Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography sx={{ fontWeight: 700 }}>Total Amount:</Typography>
                        <Typography >{currency === "INR" ? `₹${totalAmount}` : `$${totalAmount}`}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        {hasSubscription && (
                            <Button variant="contained" onClick={() => handlePayment(true)} sx={{ mb: 0.5 }}>
                                Pay Next Month Bill
                            </Button>
                        )}
                    </Box>
                    {isPaymentOn && (
                        <NextBillPaymentComponent
                            handleClose={handlePayment}
                            amount={totalAmount}
                            currency={currency}
                        />
                    )}
                </Card>
            ) : (
                <>
                    {isAppsumoUser ?
                        (
                            <Box sx={{ padding: '5px',margin:'1rem',mb: 0 }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    You have lifetime subscription of {lifeTimePlanName} plan.
                                </Typography>
                            </Box>
                        )
                        : (
                            <Box sx={{ padding: '5px', margin:'1rem', mb: 0 }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    {hasTrailPeriod ? (
                                        <>
                                            You are currently on a trial plan.
                                            <Typography sx={{ fontWeight: 700, color: 'text.secondary' }}>Expires On:</Typography>
                                            <Typography sx={{ color: 'text.secondary' }}>
                                                {CommonHelper.formatDateAndTime(expireDate, 'date')}
                                            </Typography>
                                        </>
                                    ) : (
                                        "You don't have any active subscriptions."
                                    )}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                                    To access premium features, please subscribe to one of our plans.
                                </Typography>
                            </Box>
                        )}
                </>

            )}
        </Box>
    );
};

export default NextPlanBillDetails;