import axios from 'axios';
import { Constants } from 'src/constants/stringConstants';
import { CommonHelper } from 'src/utils/commonHelper';

// Helper function to create headers
const GetRequestHeader = (contentType = '', brandId = '', token = '') => {
    const headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': contentType === 'form' ? 'multipart/form-data' : 'application/json',
        'BrandId': brandId,
        'Authorization': token,
    };
    return headers;
};

// GET request function
const Get = ({ url, responseType, isNeedAutho = true, token, brandId, successCallback, failureCallback }) => {
    const config = { headers: GetRequestHeader('', brandId,token) };
    if (responseType) config.responseType = responseType;

    return axios
        .get(url, config)
        .then(response => {
            if (response.status === 200) {
                const { data } = response;
                if (data.http_code === 200) {
                    successCallback?.(data.data, data.message);
                } else if (data.http_code === 401) {
                    CommonHelper.Logout();
                } else {
                    failureCallback?.(data.message);
                }
            } else if (response.status === 401) {
                CommonHelper.Logout();
            } else {
                failureCallback?.(Constants.SomethingWentWrongMsg);
            }
        })
        .catch(error => {
            console.error(error);
            failureCallback?.(Constants.SomethingWentWrongMsg);
        });
};

// POST request function
const Post = ({ url, body, successCallback, failureCallback, contentType, token, brandId, responseType, isNeedAutho = true, isFileUpload = false, progressCallback }) => {
    const config = {
        headers: GetRequestHeader(contentType, brandId, token),
        onUploadProgress: isFileUpload ? data => {
            const progress = Math.round((100 * data.loaded) / data.total);
            progressCallback?.(progress);
        } : undefined
    };
    if (responseType) config.responseType = responseType;

    return axios
        .post(url, body, config)
        .then(response => {
            if (response.status === 200) {
                const { data } = response;
                if (data.http_code === 200) {
                    successCallback?.(data.data, data.message);
                } else if (data.http_code === 401) {
                    CommonHelper.Logout();
                } else {
                    failureCallback?.(data.message);
                }
            } else if (response.status === 401) {
                CommonHelper.Logout();
            } else {
                failureCallback?.(Constants.SomethingWentWrongMsg);
            }
        })
        .catch(error => {
            console.error(error);
            failureCallback?.(Constants.ErrorReachingServer);
        });
};

// Export the HTTP service functions
const ReportHttpServices = {
    Post,
    Get,
    GetRequestHeader,
};

export default ReportHttpServices;
